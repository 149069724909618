import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import logoWhite from "../../../js/assest/Images/logo white_1.png";
import logoColor from "../../../js/assest/Images/FYNEPAY.png";
import "./style.css";

function NavbarComponent() {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Navbar
            expand="lg"
            className="navbar"
            style={{ backgroundColor: scrolled ? "white" : "transparent" }}
        >
            <Container className="navbar-container">
                <Navbar.Brand href="/Home" className="fw-bolder logo-write">
                    <img
                        src={scrolled ? logoColor : logoWhite}  // Add the logo source here
                        alt="logo"
                        className="navbar-logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" >
                        {["Home", "About", "Services", ].map((item) => (
                            <Nav.Link
                                key={item}
                                className="navbarMenu"
                                
                                href={`/${item}`}
                                style={{ color: scrolled ? "black" : "white" }}
                            >
                                {item}
                            </Nav.Link>
                        ))}
                    </Nav>
                    <Nav style={{marginLeft:"50px"}}>
                        {["ContactUs", "Signup"].map((item) => (
                            <Nav.Link
                                key={item}
                                className="navbarMenu"
                                href={`/${item}`}
                                style={{ color: scrolled ? "black" : "white",}}
                            >
                                {item.replace("ContactUs", "Contact")}
                            </Nav.Link>
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarComponent;
